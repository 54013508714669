<i18n>
{
  "en": {
    "video_search": "Find Movies",
    "sort_label": "Sort Order",
    "sort_newest": "Newest",
    "sort_oldest": "Oldest",
    "sort_popular": "Popular",
    "movie_status": "Status",
    "status_vip": "VIP",
    "status_svip": "SVIP",
    "status_annual": "Annual",
    "movie_type": "Type",
    "type_vr": "VR",
    "type_fhd": "Full HD",
    "type_hd": "HD",
    "type_60fps": "60 fps",
    "other": "Other",
    "category_list": "Categories",
    "categories": "Categories",
    "series": "Series",
    "actresses": "Girls"
  },
  "ja": {
    "video_search": "動画検索",
    "sort_label": "並べ替え",
    "sort_newest": "新着順",
    "sort_oldest": "古い順",
    "sort_popular": "人気順",
    "movie_status": "配信ステータス",
    "status_vip": "VIP動画",
    "status_svip": "超VIP動画",
    "status_annual": "年間動画",
    "movie_type": "動画タイプ",
    "type_vr": "VR",
    "type_fhd": "Full HD",
    "type_hd": "HD",
    "type_60fps": "60 fps",
    "other": "その他",
    "category_list": "カテゴリー一覧",
    "categories": "カテゴリー",
    "series": "シリーズ一覧",
    "actresses": "女のコ検索"
  }
}
</i18n>

<template>
<div>
  <!-- mobile: header list -->
  <div class="filter-contents" v-if="layout === 'header'">
    <div class="filter-section">
      <p class="filter-heading">{{ $t('sort_label') }}</p>
      <ul>
        <li><router-link to="/list/?page=1&o=newest"><i class="iconf-keyboard-arrow-right is-left"></i>{{ $t('sort_newest') }}</router-link></li>
        <li><router-link to="/list/?page=1&o=oldest"><i class="iconf-keyboard-arrow-right is-left"></i>{{ $t('sort_oldest') }}</router-link></li>
        <li><router-link to="/list/?page=1&o=bob"><i class="iconf-keyboard-arrow-right is-left"></i>{{ $t('sort_popular') }}</router-link></li>
      </ul>
    </div>
    <div class="filter-section" v-if="!newXPEligible">
      <p class="filter-heading">{{ $t('movie_status') }}</p>
      <ul>
        <li><router-link to="/search/?c=50000"><i class="iconf-keyboard-arrow-right is-left"></i>{{ $t('status_vip') }}</router-link></li>
        <li><router-link to="/search/?c=50001"><i class="iconf-keyboard-arrow-right is-left"></i>{{ $t('status_svip') }}</router-link></li>
        <li><router-link to="/search/?c=50003"><i class="iconf-keyboard-arrow-right is-left"></i>{{ $t('status_annual' )}}</router-link></li>
      </ul>
    </div>
    <div class="filter-section">
      <p class="filter-heading">{{ $t('movie_type') }}</p>
      <ul>
        <li><router-link to="/search/?c=60000"><i class="iconf-keyboard-arrow-right is-left"></i>{{ $t('type_fhd') }}</router-link></li>
        <li><router-link to="/search/?c=60002"><i class="iconf-keyboard-arrow-right is-left"></i>{{ $t('type_hd') }}</router-link></li>
        <li><router-link to="/search/?c=60001"><i class="iconf-keyboard-arrow-right is-left"></i>{{ $t('type_60fps') }}</router-link></li>
      </ul>
    </div>
    <div class="filter-section">
      <p class="filter-heading">{{ $t('other') }}</p>
      <ul>
        <li><router-link to="/category/"><i class="iconf-keyboard-arrow-right is-left"></i>{{ $t('category_list') }}</router-link></li>
        <li><router-link to="/series/"><i class="iconf-keyboard-arrow-right is-left"></i>{{ $t('series') }}</router-link></li>
      </ul>
    </div>
  </div>

  <!-- desktop: sidebar list -->
  <div class="sidebar" v-if="layout === 'sidebar'">
    <section>
      <div class="sidebar-heading">{{ $t('sort_label') }}</div>
      <ul class="has-style">
        <li><router-link to="/list/?page=1&o=newest">{{ $t('sort_newest') }}</router-link></li>
        <li><router-link to="/list/?page=1&o=oldest">{{ $t('sort_oldest') }}</router-link></li>
        <li><router-link to="/list/?page=1&o=bob">{{ $t('sort_popular') }}</router-link></li>
      </ul>
    </section>
    <section v-if="!newXPEligible">
      <div class="sidebar-heading">{{ $t('movie_status') }}</div>
      <ul class="has-style">
        <li><router-link to="/search/?c=50000">{{ $t('status_vip') }}</router-link></li>
        <li><router-link to="/search/?c=50001">{{ $t('status_svip') }}</router-link></li>
        <li><router-link to="/search/?c=50003">{{ $t('status_annual' )}}</router-link></li>
      </ul>
    </section>
    <section>
      <div class="sidebar-heading">{{ $t('categories') }}</div>
      <ul class="has-style">
        <li v-for="(category, index) in categoryList" :key="index">
          <router-link v-if="categoryMap[category]" :to="`/search/?c=${categoryMap[category].category_id}`">{{ categoryMap[category].name[locale] }}<small>({{ categoryMap[category].movie_count }})</small></router-link>
        </li>
        <li><router-link to="/category/">{{ $t('category_list') }}<i class="iconf-keyboard-arrow-right is-right"></i></router-link></li>
      </ul>
    </section>
    <section>
      <div class="sidebar-heading">{{ $t('movie_type') }}</div>
      <ul class="has-style">
        <li v-if="showVRFilter"><router-link :to="`/search/?c=${vrCategoryID}`">{{ $t('type_vr') }}</router-link></li>
        <li><router-link to="/search/?c=60000">{{ $t('type_fhd') }}</router-link></li>
        <li><router-link to="/search/?c=60002">{{ $t('type_hd') }}</router-link></li>
        <li><router-link to="/search/?c=60001">{{ $t('type_60fps') }}</router-link></li>
      </ul>
    </section>
    <section>
      <div class="sidebar-heading">{{ $t('other') }}</div>
      <ul class="has-style">
        <li><router-link to="/series/">{{ $t('series') }}<i class="iconf-keyboard-arrow-right is-right"></i></router-link></li>
      </ul>
    </section>
  </div>
</div>
</template>

<script>
/* eslint max-len: 0 */
import BifrostAPI from '@/assets/js/services/Bifrost/API';

const VR_CATEGORY_ID = 94;

export default {
  props: {
    layout: {
      type: String,
      required: true,
      default: 'sidebar',
    },
    categoriesHighlight: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      categories: {},
      categoryMap: {},
      categoryList: [],
    };
  },
  async created() {
    // non-reactive consts
    this.vrCategoryID = VR_CATEGORY_ID;

    // get categories and map by name, so we can show a specific list of categories based on
    // the category-list attribute
    if (this.categoriesHighlight) {
      const bfAPI = new BifrostAPI();
      this.categories = await bfAPI.getCategories();
      this.categoryList = this.categoriesHighlight.split(',');

      Object.keys(this.categories).forEach((categoryId) => {
        if (this.categoryList.includes(this.categories[categoryId].name.ja)) {
          this.categoryMap[this.categories[categoryId].name.ja] = this.categories[categoryId];
        }
      });
    }
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEligible() {
      return this.$store.getters['user/isNewXPEligible'];
    },
    showVRFilter() {
      // show VR filter option if VR movies exist AND if we are in the classic experience
      // OR
      // if VR movies exist AND we are in the new experence and the config to hide VR is disabled
      return (Object.prototype.hasOwnProperty.call(this.categories, VR_CATEGORY_ID)
        && this.categories[VR_CATEGORY_ID].movie_count > 0
        && (!this.newXPEligible
          || (this.newXPEligible && process.env.VUE_APP_NEW_XP_HIDE_VR === 'false')));
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
