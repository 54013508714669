<i18n>
{
  "en": {
    "list_all": "All Movies",
    "search_results": "Search Results",
    "search_results_series_0": "Series '",
    "search_results_series_1": "'",
    "search_results_fts_0": "Search Results for '",
    "search_results_fts_1": "'",
    "actress_results_0": "'",
    "actress_results_1": "' Appearances",
    "year_results": " Release Year",
    "filter": "Filter",
    "sort_label": "Sort Order",
    "sort_newest": "Newest Movies",
    "sort_oldest": "Oldest Movies",
    "sort_popular": "Popular Movies",
    "movie_status": "Status",
    "movie_type": "Type",
    "movie_year_released": "Year Released"
  },
  "ja": {
    "list_all": "作品一覧",
    "search_results": "検索結果",
    "search_results_series_0": "「",
    "search_results_series_1": "」シリーズ",
    "search_results_fts_0": "「",
    "search_results_fts_1": "」の検索結果",
    "actress_results_0": "「",
    "actress_results_1": "」 出演",
    "year_results": "年配信",
    "filter": "フィルタ",
    "sort_label": "並べ替え",
    "sort_newest": "新着順",
    "sort_oldest": "古い順",
    "sort_popular": "人気順",
    "movie_status": "配信ステータス",
    "movie_type": "動画タイプ",
    "movie_year_released": "配信年"
  }
}
</i18n>

<template>
<div>
  <div class="list-title">
    <h1>
      <!-- list header: sort order -->
      <span v-if="$route.name === 'movie-list' && $route.query.o">{{ selectedSortOrder }}</span>
      <!-- list header: default -->
      <span v-else-if="$route.name === 'movie-list'">{{ $t('list_all') }}</span>
      <!-- list header: category results -->
      <span v-else-if="$route.name === 'movie-search' && ($route.query.c) && categoryMap[$route.query.c]">{{ $t('search_results_fts_0') }}{{ categoryMap[$route.query.c].name[locale] }}{{ $t('search_results_fts_1') }}</span>
      <!-- list header: category/type results -->
      <span v-else-if="$route.name === 'movie-search' && ($route.query.mt || $route.query.mc)">{{ $t('search_results') }}</span>
      <!-- list header: text search results -->
      <span v-else-if="$route.name === 'movie-search' && $route.query.s">{{ $t('search_results_fts_0') }}{{ $route.query.s }}{{ $t('search_results_fts_1') }}</span>
      <!-- list header: series list -->
      <span v-else-if="$route.name === 'movie-search' && $route.query.sr && seriesMap[$route.query.sr]">{{ $t('search_results_series_0') }}{{ seriesMap[$route.query.sr].name[locale] }}{{ $t('search_results_series_1') }}</span>
      <!-- list header: actress list -->
      <span v-else-if="$route.name === 'movie-search' && $route.query.a">{{ $t('actress_results_0') }}{{ selectedActress }}{{ $t('actress_results_1') }}</span>
      <!-- list header: year list -->
      <span v-else-if="$route.name === 'movie-search' && $route.query.my">{{ $route.query.my }}{{ $t('year_results') }}</span>

      <!-- list count + current location -->
      <span class="search-count" v-if="locale == 'ja'">({{ totalMovies }}件中 {{ offset + 1 }}-{{ (offset + listSize) > totalMovies ? totalMovies : offset + listSize }}件)</span>
      <span class="search-count" v-if="locale == 'en'">({{ offset + 1 }}-{{ (offset + listSize) > totalMovies ? totalMovies : offset + listSize }} of {{ totalMovies }})</span>
    </h1>
  </div>

  <div class="list-filter">
    <button class="filter-toggle button is-flat is-large is-fullwidth" :class="{'is-open': showFilterMenu}" @click="submenuToggle()">
      <i class="iconf-filter is-left"></i>{{ $t('filter') }}
    </button>

    <!-- filter (media query on smaller screens) -->
    <transition name="slide">
      <div v-if="showFilterMenu">
        <filter-list layout="header" />
      </div>
    </transition>
  </div>
</div>
</template>

<script>
/* eslint max-len: 0 */

import range from 'lodash/range';
import keyBy from 'lodash/keyBy';
import findIndex from 'lodash/findIndex';
import startCase from 'lodash/startCase';
import APP_CONFIG from '@/appConfig';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import FilterList from '@/components/movieList/filterList.vue';

// maximum width until header menu hides (if toggled open)
// needs this in case the user has a small screen, toggles the filter open, and then widens the
// screen. when this happens, both filters are visible
const MAX_WIDTH_FILTER = 959;

export default {
  props: {
    totalMovies: {
      type: Number,
      required: true,
    },
    offset: {
      type: Number,
      required: true,
    },
    listSize: {
      type: Number,
      required: true,
    },
  },
  components: {
    'filter-list': FilterList,
  },
  data() {
    return {
      sortType: 'newest',
      categoriesList: [],
      selectedCategories: [],
      categoryMap: {},
      seriesMap: {},
      selectedActress: '',
      yearSelected: this.$route.query.my || (new Date()).getFullYear(),
      showFilterMenu: false,
      selectedSortOrder: '',
    };
  },
  mounted() {
    // set search input initial state
    this.setFilterMenuState();
    this.$nextTick(() => {
      window.addEventListener('resize', this.setFilterMenuState);
    });
  },
  created() {
    // non-reactive consts
    this.currentYear = (new Date()).getFullYear();
    this.yearRange = range(2003, (new Date()).getFullYear() + 1).reverse();

    this.sortType = this.$route.query.o;

    const bfAPI = new BifrostAPI();

    // get categories info for category queries
    bfAPI.getCategories().then((result) => {
      this.categoryMap = Object.assign({}, APP_CONFIG.categories, result);
    });

    // get selected categories
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'c')) {
      this.selectedCategories = this.$route.query.c.split(',');
    }

    // get series info for series queries
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'sr')) {
      bfAPI.getSeries().then((result) => {
        this.seriesList = result;

        // build a series map
        this.seriesMap = keyBy(this.seriesList, 'series_id');
      });
    }

    // get actress info for actress queries
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'a')) {
      bfAPI.getMovieListActress(0, 1, this.$route.query.a).then((result) => {
        // find index of actress in Actress data (why? because some movies have more than 1 actress)
        const actressIdx = findIndex(result.Rows[0].ActorID, item => item === parseInt(this.$route.query.a, 10));

        // set title - actress names are keyed as 'ActressesJa', and 'ActressesEn', so capitalize 1st letter of locale
        const actressKey = `Actresses${startCase(this.locale)}`;
        this.selectedActress = result.Rows[0][actressKey][actressIdx];
      });
    }

    // get selected sort order display text
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'o')) {
      switch (this.$route.query.o) {
        case 'newest':
          this.selectedSortOrder = this.$t('sort_newest');
          break;
        case 'oldest':
          this.selectedSortOrder = this.$t('sort_oldest');
          break;
        case 'bob':
          this.selectedSortOrder = this.$t('sort_popular');
          break;
        default:
          this.selectedSortOrder = this.$t('sort_newest');
          break;
      }
    }
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    submenuToggle() {
      this.showFilterMenu = !this.showFilterMenu;
    },
    setFilterMenuState() {
      if (window.innerWidth > MAX_WIDTH_FILTER) this.showFilterMenu = false;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setFilterMenuState);
  },
};
</script>

<style lang="scss" scoped>
.slide-enter-active {
   transition-duration: 0.3s;
   transition-timing-function: ease-in;
}

.slide-leave-active {
   transition-duration: 0.3s;
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
   max-height: 400px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
</style>
