<template>
<div v-if="bannerImgSrc" class="banner">
  <img :src="`${bannerImgSrc}`" class="banner-image">
</div>
</template>

<script>
/* eslint max-len: 0 */
import BifrostAPI from '@/assets/js/services/Bifrost/API';

export default {
  data() {
    return {
      bannerImgSrc: null,
    };
  },
  async created() {
    const bfAPI = new BifrostAPI();
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'sr')) {
      // check if this series has a banner
      const seriesAll = await bfAPI.getSeries();
      if (Object.prototype.hasOwnProperty.call(seriesAll[this.$route.query.sr].banners, this.locale)) {
        this.bannerImgSrc = `/dyn/dla/images/${seriesAll[this.$route.query.sr].banners[this.locale]}`;
      }
    } else if (Object.prototype.hasOwnProperty.call(this.$route.query, 'a')) {
      // check if this actress has a banner
      const actress = await bfAPI.getActress(this.$route.query.a);
      if (actress.images.banner) {
        this.bannerImgSrc = `/dyn/dla/images/${actress.images.banner}`;
      }
    }
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
