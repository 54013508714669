<i18n>
{
  "en": {
    "vip_badge": "VIP",
    "svip_badge": "SVIP",
    "annual_badge": "Annual",
    "limited_tag": "Limited Availability",
    "expired_tag": "Availability Ended",
    "future_tag": "Upcoming",
    "vr_tag": "VR",
    "play_sample": "Play Sample",
    "sort_label": "Sort",
    "sort_newest": "Newest",
    "sort_oldest": "Oldest",
    "sort_popular": "Popular",
    "first_page": "«",
    "last_page": "»",
    "prev_page": "‹",
    "next_page": "›",
    "hover_added_0": "Added to playlist '",
    "hover_added_1": "'",
    "ntf_add_playlist_0": "Added to playlist '",
    "ntf_add_playlist_1": "' successfully",
    "ntf_remove_playlist_0": "Removed from playlist '",
    "ntf_remove_playlist_1": "' successfully"
  },
  "ja": {
    "vip_badge": "VIP",
    "svip_badge": "超VIP",
    "annual_badge": "年間",
    "limited_tag": "期間限定",
    "expired_tag": "配信期間終了",
    "future_tag": "近日配信",
    "vr_tag": "VR",
    "play_sample": "サンプル再生",
    "sort_label": "並べ替え",
    "sort_newest": "配信日が新しい順",
    "sort_oldest": "配信日が古い順",
    "sort_popular": "人気順",
    "first_page": "«",
    "last_page": "»",
    "prev_page": "‹",
    "next_page": "›",
    "hover_added_0": "「",
    "hover_added_1": "」に追加しました",
    "ntf_add_playlist_0": "「",
    "ntf_add_playlist_1": "」に追加しました",
    "ntf_remove_playlist_0": "「",
    "ntf_remove_playlist_1": "」から削除されました"
  }
}
</i18n>

<template>
  <div class="inner-container">
    <list-banner />

    <movie-list-header :totalMovies="totalMovies" :offset="offset" :listSize="listSize" />

    <div class="grid is-movie is-card">
      <div v-for="(movie, index) in movieList" :key="movie.MovieID" class="grid-item">
        <div class="entry">
          <div class="entry-media">
            <div v-if="!newXPEligible && movie.isVIP" class="tag-member is-vip">{{ $t('vip_badge') }}</div>
            <div v-if="!newXPEligible && movie.isSVIP" class="tag-member is-svip">{{ $t('svip_badge') }}</div>
            <div v-if="!newXPEligible && movie.isAnnual" class="tag-member is-annual">{{ $t('annual_badge') }}</div>
            <div class="media-thum" :class="[playlistEnabled ? (movie.isWatchLaterChecked ? 'pl-overlay-saved' : 'pl-overlay') : '']">
              <router-link :to="`/movies/${movie.MovieID}/`"><span class="movie-area"></span></router-link>
              <span class="pl-area" :title="[movie.isWatchLaterChecked ? $t('hover_added_0') + watchLaterName + $t('hover_added_1') : watchLaterName]" @click="toggleWatchLater(movie.MetaMovieID, movie.MovieID, movie.isWatchLaterChecked, index)"></span>
              <img :src="`${movie.thumbnailSrc}`"
                @error="useFallbackImage(index)"
                class="media-image"
                loading="lazy"
                v-sfw
              />
            </div>
          </div>
          <div class="entry-meta has-button">
            <router-link :to="`/movies/${movie.MovieID}/`">
              <div v-if="movie.IsLimited" class="tag-status is-limited">{{ $t('limited_tag') }}</div>
              <div v-if="movie.IsExpired" class="tag-status is-expired">{{ $t('expired_tag') }}</div>
              <div v-if="movie.IsComingSoon" class="tag-status is-future">{{ $t('future_tag') }}</div>
              <div v-if="movie.isVR" class="tag-status is-vr">{{ $t('vr_tag') }}</div>
              <div class="meta-data">{{ movie.ReleaseDate }}<span v-if="movie.ExpireDate"> &ndash; {{ movie.ExpireDate }}</span></div>
              <!-- actress -->
              <!-- multiple actress names spacing -> jp: space / en: comma + space -->
              <!-- need 2 x '&#32;' to get 1 space -->
              <div class="meta-title" v-if="locale == 'ja'"><span v-for="(actress, index) in movie.ActressesJa" :key="index">{{ actress }}<span v-if="index + 1 < movie.ActressesJa.length">&#32;&#32;</span></span></div>
              <div class="meta-title" v-if="locale == 'en'"><span v-for="(actress, index) in movie.ActressesEn" :key="index">{{ actress }}<span v-if="index + 1 < movie.ActressesEn.length">,&#32;</span></span></div>
              <!-- age -->
              <age-tag :movie="movie" />
            </router-link>
            <div class="meta-button">
              <button class="button is-filled is-xsmall is-color-text" @click="showModalSample(movie.MovieID);"><svg class="dl-icon is-left"><use xlink:href="#dl-icon-play-fill" /></svg>{{ $t('play_sample')}}</button>
            </div>
          </div>
        </div>
      </div>
    </div><!-- /.grid -->

    <div class="pagination-large section">
      <uib-pagination
        v-model="pagination"
        :boundary-links="false"
        :boundary-link-numbers="true"
        :force-ellipses="true"
        :max-size="10"
        first-text=""
        last-text=""
        previous-text=""
        next-text=""
        :total-items="totalMovies"
        :items-per-page="listSize"
        @change="pageChanged()" />
    </div>

    <div class="pagination-small section">
      <mobile-pagination
        v-model="pagination"
        :total-items="totalMovies"
        :items-per-page="listSize"
        @changeOnMobile="mobilePageChanged" />
    </div>
  </div><!-- /.inner-container -->
</template>

<script>
/* eslint max-len: 0 */

import Deferred from '@/assets/js/utils/Deferred';
import MovieHelper from '@/assets/js/utils/movie';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import Analytics from '@/assets/js/utils/Analytics';
import EventBus from '@/assets/js/utils/EventBus';
import ListBanner from '@/components/movieList/listBanner.vue';
import AgeTag from '@/components/_common/ageTag.vue';
import MovieListHeader from './movieListHeader.vue';
import MobilePagination from './mobilePagination.vue';

const WATCH_LATER_PLAYLIST_NAME = 'watch_later';
const bfAPI = new BifrostAPI();

export default {
  props: {
    listSize: Number,
  },
  components: {
    'list-banner': ListBanner,
    'movie-list-header': MovieListHeader,
    'mobile-pagination': MobilePagination,
    'age-tag': AgeTag,
  },
  data() {
    return {
      movieList: [],
      totalMovies: 0,
      queryParams: {},
      pagination: { currentPage: Number(this.$route.query.page) || 1 },
      posterFallbackTries: {},
    };
  },
  mounted() {
    // fetch movie list
    this.queryParams = this.getQueryParams();
    this.getMovieListForUser(this.userTypeID);
  },
  methods: {
    getQueryParams() {
      const queryParams = {
        // page: (this.$route.query.page) ? this.$route.query.page : 1,
        // size: this.listSize,
      };

      // all query params that this needs to care about (to keep around when the location changes)
      // o: sort preference (newest, oldest, etc)
      // s: FTS search string
      // c: category id[s]
      // a: actress id
      // am: actor id
      // mt: movie type
      // mc: movie conditions
      // ml: movie list (named)
      // sr: series id
      // my: movie year
      // cl: custom list (manually created list of movie ids)
      const MOVIE_LIST_PARAMS = [
        'o',
        's',
        'c',
        'a',
        'am',
        'mt',
        'mc',
        'ml',
        'sr',
        'my',
        'cl',
      ];

      for (let i = 0; i < MOVIE_LIST_PARAMS.length; i += 1) {
        if (this.$route.query[MOVIE_LIST_PARAMS[i]]) {
          queryParams[MOVIE_LIST_PARAMS[i]] = this.$route.query[MOVIE_LIST_PARAMS[i]];
        }
      }

      return queryParams;
    },

    getMovieListForUser(userTypeID) {
      this.getMovieList().then((result) => {
        this.totalMovies = result.TotalRows;
        const movieList = MovieHelper.expandDetailsList(result.Rows, this.locale, userTypeID);
        this.movieList = MovieHelper.filterExcludedMovieList(movieList, userTypeID);

        // check actress age
        // hide age if there are more than 1 actress in actressList
        // only display age if it's set over '18' (prevent webmaster input error and ignore data of age'0' in db)
        for (let i = 0; i < this.movieList.length; i += 1) {
          if (this.movieList[i].ActressesList && Object.keys(this.movieList[i].ActressesList).length === 1) {
            const firstActressID = Object.keys(this.movieList[i].ActressesList)[0];
            if (this.movieList[i].ActressesList[firstActressID].Age
                && this.movieList[i].ActressesList[firstActressID].Age >= 18) {
              this.$set(this.movieList[i], 'actressAge', this.movieList[i].ActressesList[firstActressID].Age);
            }
          }
        }
      });
    },

    getMovieList() {
      const defer = new Deferred();

      if (!Object.prototype.hasOwnProperty.call(this.queryParams, 'o')) {
        if (Object.prototype.hasOwnProperty.call(this.queryParams, 'c')) {
          // category results
          const categoryIDs = this.queryParams.c.split(',').map(x => parseInt(x, 10));
          bfAPI.getCategoryList(this.offset, this.listSize, categoryIDs).then((result) => {
            defer.resolve(result);
          });
        } else if (Object.prototype.hasOwnProperty.call(this.queryParams, 's')) {
          // text search results
          bfAPI.getFTS(this.queryParams.s, this.offset, this.listSize).then((result) => {
            defer.resolve(result);
          });
        } else if (Object.prototype.hasOwnProperty.call(this.queryParams, 'a')) {
          // actress search results
          bfAPI.getMovieListActress(this.offset, this.listSize, this.queryParams.a).then((result) => {
            defer.resolve(result);
          });
        } else if (Object.prototype.hasOwnProperty.call(this.queryParams, 'am')) {
          // actor search results
          bfAPI.getMovieListActor(this.offset, this.listSize, this.queryParams.am).then((result) => {
            defer.resolve(result);
          });
        } else if (Object.prototype.hasOwnProperty.call(this.queryParams, 'mt')) {
          // movie type results
          bfAPI.getMovieListType(this.offset, this.listSize, this.queryParams.mt).then((result) => {
            defer.resolve(result);
          });
        } else if (Object.prototype.hasOwnProperty.call(this.queryParams, 'mc')) {
          // movie conditions results
          bfAPI.getMovieListCondition(this.offset, this.listSize, this.queryParams.mc).then((result) => {
            defer.resolve(result);
          });
        } else if (Object.prototype.hasOwnProperty.call(this.queryParams, 'ml')) {
          // movie list results
          bfAPI.getMovieListNamed(this.offset, this.listSize, this.queryParams.ml).then((result) => {
            defer.resolve(result);
          });
        } else if (Object.prototype.hasOwnProperty.call(this.queryParams, 'sr')) {
          // movie conditions results
          bfAPI.getMovieListSeries(this.offset, this.listSize, this.queryParams.sr).then((result) => {
            defer.resolve(result);
          });
        } else if (Object.prototype.hasOwnProperty.call(this.queryParams, 'my')) {
          // movies by year results
          bfAPI.getMovieListByYear(this.offset, this.listSize, this.queryParams.my).then((result) => {
            defer.resolve(result);
          });
        } else {
          // default if no params: show newest list
          bfAPI.getMovieListNewest(this.offset, this.listSize).then((result) => {
            defer.resolve(result);
          });
        }
      } else {
        // sorted list
        switch (this.queryParams.o) {
          case 'newest': {
            bfAPI.getMovieListNewest(this.offset, this.listSize).then((result) => {
              defer.resolve(result);
            });
            break;
          }
          case 'oldest': {
            bfAPI.getMovieListOldest(this.offset, this.listSize).then((result) => {
              defer.resolve(result);
            });
            break;
          }
          case 'monthly': {
            bfAPI.getMovieListMonthly(this.offset, this.listSize).then((result) => {
              defer.resolve(result);
            });
            break;
          }
          case 'weekly': {
            bfAPI.getMovieListWeekly(this.offset, this.listSize).then((result) => {
              defer.resolve(result);
            });
            break;
          }
          case 'bob': {
            bfAPI.getMovieListBOB(this.offset, this.listSize).then((result) => {
              defer.resolve(result);
            });
            break;
          }
          case 'random': {
            // note: we send a 'seed' here (initially null) which is sent from the backend. this
            // keeps the "randomness" list consistent for the same request (in other words, the
            // entire list is randomized based on the seed so that we don't get duplinpm cate movies
            // appearing on different pages)
            bfAPI.getMovieListRandom(this.$store.getters['bifrost/randomSeed'], this.offset, this.listSize).then((result) => {
              this.$store.dispatch('bifrost/setRandomSeed', result.Seed);
              defer.resolve(result);
            });
            break;
          }
          case '6zQep9xnIMZM': {
            // reserved for SEO
            bfAPI.getMovieListSEOTemplates(this.offset, this.listSize).then((result) => {
              defer.resolve(result);
            });
            break;
          }
          default: {
            bfAPI.getMovieListNewest(this.offset, this.listSize).then((result) => {
              defer.resolve(result);
            });
            break;
          }
        }
      }
      return defer.promise;
    },

    showModalSample(movieId) {
      this.$modal.show('ui-modal-video', { movieId });
    },

    pageChanged() {
      this.$router.push({ name: this.$router.name, query: Object.assign({ page: this.pagination.currentPage }, this.queryParams) });
    },

    mobilePageChanged(targetPage) {
      // received $emit signal from mobile-pagination and route to selected page
      if (this.pagination.currentPage !== targetPage) {
        this.pagination.currentPage = targetPage;
      } else {
        // prevent "NavigationDuplicated" error from router
        return;
      }
      this.$router.push({ name: this.$router.name, query: Object.assign({ page: this.pagination.currentPage }, this.queryParams) });
    },

    useFallbackImage(index) {
      // missing movie poster thumbnail, fallback to large poster
      if (!this.posterFallbackTries[this.movieList[index].MovieID]) {
        // attempt 1: poster thumbnail is missing, use large poster
        this.$set(this.movieList[index], 'thumbnailSrc', `/assets/sample/${this.movieList[index].MovieID}/str.jpg`);
        this.posterFallbackTries[this.movieList[index].MovieID] = true;
      } else {
        // attempt 2: large poster is missing, use no-image placeholder
        this.$set(this.movieList[index], 'thumbnailSrc', '/img/common/now_printing_square.png');
      }
    },

    toggleWatchLater(movieId, movieDateId, isWatchLaterChecked, index) {
      if (isWatchLaterChecked) {
        this.deleteMovieFromPlaylist(this.watchLaterPlaylistId, movieId, movieDateId);
        this.$set(this.movieList[index], 'isWatchLaterChecked', false);
      } else {
        this.addMovieToPlaylist(this.watchLaterPlaylistId, movieId, movieDateId);
        this.$set(this.movieList[index], 'isWatchLaterChecked', true);
      }
    },

    addMovieToPlaylist(playlistId, movieId, movieDateId) {
      bfAPI.addMovieToPlaylist(playlistId, movieId).then((response) => {
        if (response.status === 200) {
          // show notification
          EventBus.$emit('notification:event:generic', {
            text: this.$t('ntf_add_playlist_0') + this.watchLaterName + this.$t('ntf_add_playlist_1'),
            duration: 3000,
          });

          // analytics
          this.$analytics.trackEvent('Playlist', 'Add Movie to "Watch Later"', movieDateId);
          bfAPI.getMovieDetail(movieDateId).then((res) => {
            Analytics.sendCustomEvent('playlist', {
              playlist_action: 'Add Movie to Playlist',
              playlist_type: 'Default',
              playlist_name: 'Watch Later',
              movie_id: movieDateId,
              movie_title: res.Title,
              actress_name: res.Actor,
            });
          });
        }
      });
    },

    deleteMovieFromPlaylist(playlistId, movieId, movieDateId) {
      bfAPI.deleteMovieFromPlaylist(playlistId, movieId, movieDateId).then((response) => {
        if (response.status === 200) {
          // show notification
          EventBus.$emit('notification:event:generic', {
            text: this.$t('ntf_remove_playlist_0') + this.watchLaterName + this.$t('ntf_remove_playlist_1'),
            duration: 3000,
          });

          // analytics
          bfAPI.getMovieDetail(movieDateId).then((res) => {
            Analytics.sendCustomEvent('playlist', {
              playlist_action: 'delete Movie from Playlist',
              playlist_type: 'Default',
              playlist_name: 'Watch Later',
              movie_id: movieDateId,
              movie_title: res.Title,
              actress_name: res.Actor,
            });
          });
        }
      });
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEligible() {
      return this.$store.getters['user/isNewXPEligible'];
    },
    page() {
      return (this.$route.query.page) ? this.$route.query.page : 1;
    },
    offset() {
      return (this.page - 1) * this.listSize;
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
    playlistEnabled() {
      return this.$store.getters['playlist/isEnabled'];
    },
    translationMap() {
      return this.$store.getters['playlist/translationMap'];
    },
    watchLaterName() {
      return this.translationMap[WATCH_LATER_PLAYLIST_NAME][this.locale];
    },
    defaultPlaylistIds() {
      return this.$store.getters['playlist/defaultPlaylistIDs'];
    },
    watchLaterPlaylistId() {
      return this.defaultPlaylistIds[WATCH_LATER_PLAYLIST_NAME];
    },
  },
  watch: {
    userTypeID(newVal) {
      this.getMovieListForUser(newVal);
    },
    listSize(newVal, oldVal) {
      if (newVal !== oldVal) this.getMovieListForUser(this.userTypeID);
    },
  },
};
</script>

<style lang="scss" scoped>

.entry-media .media-thum .movie-area {
  content: "";
  display: inline-block;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 90%;
  z-index: 1;
}

.entry-media .media-thum .pl-area {
  content: "";
  display: inline-block;
  position: absolute;
  height: 25px;
  right: 5px;
  bottom: 5px;
  width: 25px;
  z-index: 1;
  cursor: pointer;
}

.entry-media .media-thum.pl-overlay:before, .entry-media .media-thum.pl-overlay-saved:before {
  content: "";
  display: inline-block;
  position: absolute;
  height: 25px;
  right: 5px;
  bottom: 5px;
  width: 25px;
  border-radius: 2px;
  z-index: 1;
  opacity: 0;
  -moz-transition: opacity .5s ease-in-out;
  -webkit-transition: opacity .5s ease-in-out;
  -o-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out;
}

.entry-media .media-thum.pl-overlay:hover:before {
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23ffffff' d='M12.516 6.984v5.25l4.5 2.672-0.75 1.266-5.25-3.188v-6h1.5zM12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648-5.648-2.367-5.648 2.367-2.367 5.648 2.367 5.648 5.648 2.367zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93z'/%3E%3C/svg%3E") 0 0 no-repeat;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 1;
}

.entry-media .media-thum.pl-overlay-saved:hover:before {
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23ffffff' d='M9.984 17.016l9-9-1.406-1.453-7.594 7.594-3.563-3.563-1.406 1.406zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93z'/%3E%3C/svg%3E") 0 0 no-repeat;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 1;
}

/* overwrite */
.media-thum:hover .media-image {
  opacity: .8
}
</style>
